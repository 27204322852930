<template>
  <el-drawer
    :title="title"
    :visible.sync="drawerDetail"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    :append-to-body="true"
    :modal-append-to-body="false"
    custom-class="drawerClass"
    size="70%"
  >
    <div v-if="titleType === '流水账'" style="padding: 15px 30px 10px 30px">
      <el-row style="margin-bottom: 5px">
        <el-col :span="12">
          <div
            class="grid-content bg-purple"
            style="padding-left: 10px; margin-bottom: 20px"
          >
            <el-switch
              v-for="(item, index) in filterList"
              :key="index"
              style="margin-right: 30px"
              v-model="item.value"
              :active-text="item.key"
              @change="switchChange($event, index, item)"
            >
            </el-switch>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <div class="block" style="margin-bottom: 20px">
            <el-date-picker
              v-model="range"
              type="daterange"
              style="width: 250px; margin-left: 10px"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="changeTime"
            >
            </el-date-picker>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <div class="block" style="padding-left: 18px">
            <!-- zhongte -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else style="padding: 15px 30px 10px 30px">
      <el-row style="margin-bottom: 5px">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <div style="margin-bottom: 20px">催款描述:</div>
            <!-- /maxlength="500" -->
            <el-input
              type="textarea"
              v-model="textareaValue"
              show-word-limit
              resize="none"
              :autosize="{ minRows: 2, maxRows: 4 }"
              class="textarea-box"
            ></el-input>
          </div>
        </el-col>
      </el-row>
    </div>

    <div style="padding: 20px">
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <el-table-column prop="CreateTime" label="日期">
          <template slot-scope="scope">
            {{ scope.row.CreateTime?.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column prop="Type" label="类型" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.Type == 0 || scope.row.Type == 1">{{
              scope.row.Type == 0
                ? "还款"
                : scope.row.Type == 1
                ? "挂账"
                : "作废"
            }}</span>
            <span
              v-else
              style="color: red; font-weight: bold; font-size: 20px"
              >{{
                scope.row.Type == 0
                  ? "还款"
                  : scope.row.Type == 1
                  ? "挂账"
                  : "作废"
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="Money" label="金额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Money) }}
          </template>
        </el-table-column>
        <el-table-column prop="Owing" label="余额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Owing) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.Type != 0"
              type="text"
              @click="handleView(scope.row)"
              >明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0" @current-change="currentChange"
        @size-change="handleSizeChange">
      </el-pagination> -->
    </div>

    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawerDetail = false">取消</el-button>
      <!-- <el-button v-if="titleType==='添加'" @click="arrearDetailSave" type="primary">保存</el-button>
      <el-button v-else  @click="arrearDetailDelete" type="danger">删除</el-button> -->
    </div>
    <!-- <el-dialog title="编辑商品分类" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" append-to-body>
      <el-input v-model="typeObj.Name" placeholder="请输入商品分类名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="dialogLoading" type="primary" @click="dialogConfirm">确 定</el-button>
      </span>
    </el-dialog> -->

    <!-- 小票页面组件 -->
    <ReceiptPage ref="receiptPage" :parentObj="{ ...this }" />
    <!-- 回执单组件 -->
    <Form ref="Form" :parentObj="{ ...this }" />
    <!-- 明细组件 -->
    <ProductDetail ref="ProductDetail" :parentObj="{ ...this }" />
  </el-drawer>
</template>

<script>
import ProductDetail from "./ProductDetail.vue";
import { getMonthRange, toThousands } from "../../utils/Tools";
// 引入小票页面
import ReceiptPage from "./Receipt.vue";
import Form from "../Overdue/Form.vue";
export default {
  components: {
    Form,
    ReceiptPage,
    ProductDetail,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      getMonthRange,
      toThousands,
      filterList: [
        {
          key: "近一月",
          value: true,
        },
        {
          key: "近三月",
          value: false,
        },
        {
          key: "近半年",
          value: false,
        },
      ],
      // 近一月
      value1: true,
      // 近三月
      value3: false,
      // 近半年
      value6: false,
      // 文本域
      textareaValue: "尊敬的客户，您本月账单已逾期，如已支付请忽略。",
      // 日期
      range: [],
      drawerDetail: false,
      direction: "rtl",
      btnLoading: false,
      title: "催款",
      loading: false,
      timeout: null,
      entity: {},
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: {
        // 客户id
        CustomerId: "",
        // 开始时间
        StartTime: "",
        // 结束时间
        EndTime: "",
      },
      sortList: [1, 2, 3, 4, 5, 6],
      selectRows: [],
      activeName: "2",
      tagList: [],
      dialogVisible: false,
      typeObj: {},
      dialogLoading: false,
      // 传递过来的对账单列表id
      BillConfirmId: "",
      // 客户id
      CustomerId: "",
      CompanyId: "",
      // 时间范围
      range: [],
      // 点击的是添加还是查看
      titleType: "",
      // 默认月份  1  近一月   3  近三月   6  近半年
      Month: "1",
      obj: {},
    };
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 表格中判断是否作废  作废显示红色区域
    tableRowClassName({ row, rowIndex }) {
      console.log("打印row了", row, rowIndex);
      // if(row){

      //       return {
      //       backgroundColor:"rgba(247,124,165,1)"
      //     }

      // }
    },

    // 流水账 近一月  近三月  近半年  切换
    switchChange(e, index, item) {
      console.log("近一月", e, index, item, this.filterList);
      item.value = e;
      let Month = "1";
      if (index == 0 && e == true) {
        this.filterList[1].value = false;
        this.filterList[2].value = false;
        this.filterList[0].value = true;
        Month = "1";
      } else if (index == 1 && e == true) {
        this.filterList[1].value = true;
        this.filterList[2].value = false;
        this.filterList[0].value = false;
        Month = "3";
      } else {
        this.filterList[1].value = false;
        this.filterList[2].value = true;
        this.filterList[0].value = false;
        Month = "6";
      }

      console.log("打印obj", this.obj);
      this.obj.Month = Month;
      this.tableData = [];
      this.getBillConfirmList(this.obj);
    },
    // 对账单添加
    arrearListAdd() {},
    // 开始日期结束日期选择事件
    // 开始日期结束日期选择事件
    changeTime(e) {
      console.log("时间选择", e, this.obj);
      this.obj.StartTime = e[0];
      this.obj.EndTime = e[1];
      this.tableData = [];
      this.loading = true;
      this.getBillConfirmList(this.obj);
    },
    dialogConfirm() {
      if (!this.typeObj.Name) return this.$message.error("请输入商品分类名称");
      if (!this.typeObj.CompanyId)
        this.typeObj.CompanyId = this.queryParam.CommpayId;
      this.dialogLoading = true;
      this.$http.post("/BO/InventType/SaveData", this.typeObj).then((res) => {
        this.dialogLoading = false;
        if (res.Success) {
          this.dialogVisible = false;
          this.$message.success("操作成功");
          this.getDataList();
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 明细点击事件

    handleView(row) {
      console.log("明细点击事件", row);
      this.typeObj = row;

      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$http
        .post("/BO/BIlling/GetOrderReturnId?Id=" + row.BillId)
        .then((res) => {
          loading.close();
          if (res.Success) {
            if (res.Data.Type == 0) {
              console.log("打开小票页面");
              // 打开小票页面
              this.$refs.receiptPage.openForm(res.Data.Id);
            } else {
              // 打开回执单页面
              this.$refs.Form.openForm(res.Data.Id);
            }
          }
        });
    },
    // 分类新增
    handleAdd() {},
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    handleSelectionChange(val) {
      this.selectRows = val;
    },

    handleDelete(scope) {
      this.tableData.splice(scope.$index, 1);
    },
    init() {
      this.drawerDetail = true;
    },

    // 删除
    arrearDetailDelete() {
      this.$http
        .post("/BO/BIllcollection/DeleteConfirmData", [this.BillConfirmId])
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            // 刷新父组件的列表方法
            this.parentObj.tableData = [];
            this.parentObj.getBillConfirmList();
            this.$message.success("已删除");
            // 关闭当前弹窗
            this.drawerDetail = false;
          } else {
            this.$message.error(resJson.Msg);
            this.loading = false;
          }
        });
    },

    // 保存
    arrearDetailSave() {
      this.loading = true;

      console.log("打印父组件", this.parentObj);
      this.$http
        .post("/BO/BIllcollection/SaveBillConfirmData", this.entity)
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            // 刷新父组件的列表方法
            this.parentObj.tableData = [];
            this.parentObj.getBillConfirmList();
            this.$message.success("已保存");
            // 关闭当前弹窗
            this.drawerDetail = false;
          } else {
            this.$message.error(resJson.Msg);
            this.loading = false;
          }
        });
    },
    // 获取催款表格列表数据
    getBillConfirmList(obj) {
      this.loading = true;
      this.$http
        .post("/BO/BIllcollection/GetDataList", {
          Search: {
            CustomerId: obj.Id,
            EndTime: obj.EndTime,
            StartTime: obj.StartTime,
            Month: obj.Month,
          },
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            console.log(
              "获取催款催款催款催款催款催款催款催款催款催款催款催款催款详情详情详情",
              resJson.Data
            );
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          } else {
            this.loading = false;
          }
        });
    },
    openForm(titleType, obj) {
      this.obj = obj;
      this.title = titleType;
      this.titleType = titleType;
      this.CustomerId = obj.Id;
      this.range = obj.range;
      console.log("传递过来的两个id", titleType, obj);
      this.init();
      if (titleType === "流水账") {
        let range = getMonthRange(1);
        this.range = range;
        let StartTime = range[0];
        let EndTime = range[1];
        obj.StartTime = StartTime;
        obj.EndTime = EndTime;
        obj.Month = this.Month;
      }
      console.log("最终传递的ob最终传递的obj最终传递的objj", obj);
      // this.range = getMonthRange(1)
      // this.queryParam.StartTime = this.range[0]
      // this.queryParam.EndTime = this.range[1]
      // if (obj.BillConfirmId) {
      // this.queryParam.CustomerId = BillConfirmId;
      this.getBillConfirmList(obj);
      // }
    },
    // 获取商品分类列表数据
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/InventType/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },

    handleClose(done) {
      done();
    },
    handleSelect(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="less" scoped>
.textColor {
  color: red !important;
  font-weight: bold !important;
}
/deep/.el-drawer__header {
  margin-bottom: 10px;
}
.qianImg {
  width: 70px;
  height: 160px;
  transform: rotate(-90deg);
}

.drawerClass {
  padding: 10px;

  .posBox {
    font-weight: 600;
    width: calc(100% - 42px);
    margin-left: 42px;
    line-height: 2;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>

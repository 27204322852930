<template>
  <!-- 对账记录商品小票页面 -->
  <el-drawer
    :visible.sync="drawer"
    direction="rtl"
    destroy-on-close
    custom-class="Receipt"
    :append-to-body="true"
    size="400px"
  >
    <div class="container">
      <h3>{{ entity.CompanyName }}</h3>
      <div class="cell" style="display: flex; justify-content: space-between">
        <div>单号: {{ entity.Id }}</div>
        <div
          v-if="IsOverdue"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
          "
        >
          <img
            style="width: 50px; heigt: 50px"
            :src="require('@/assets/yuqi.png')"
            alt=""
          />
        </div>
      </div>
      <div class="cell" style="display: flex; justify-content: space-between">
        <div>日期: {{ entity.CreateTime?entity.CreateTime.substring(0, 19):'' }}</div>
        <div v-if="IsOverdue">逾期: {{ OverdueDay }} 天</div>
      </div>
      <div class="cell"><span>客户:</span>{{ entity.cCusName }}</div>
      <div
        class="tableRow"
        style="border-bottom: 1px dashed #ccc; margin-top: 10px; line-height: 2"
      >
        <span class="tableIndex"></span>
        <span class="tableCol left">品名/编码</span>
        <span class="tableCol">数量</span>
        <!-- <span class="tableCol">单位</span> -->
        <span class="tableCol">单价</span>
        <span class="tableCol">小计</span>
      </div>
      <div style="padding: 10px 0">
        <div v-for="(item, index) in data" :key="index">
          <div class="tableRow">
            <span class="tableIndex">{{ index + 1 }}</span>
            <span class="tableCol left">{{ item.InventName }}</span>
            <span class="tableCol">{{ item.Quantity }}</span>
            <span class="tableCol">{{ item.Price?item.Price.toFixed(2):'' }}</span>
            <span class="tableCol">{{ item.Money?item.Money.toFixed(2):'' }}</span>
          </div>
          <div class="tableRow">
            <span class="tableIndex"></span>
            <span class="tableCol left">{{ item.cInvCode }}</span>
            <span class="tableCol">{{ item.GroupName }}</span>
            <span class="tableCol col2">规格: {{ item.cInvStd }}</span>
          </div>
        </div>
      </div>
      <div
        class="tableRow"
        style="border-top: 1px dashed #ccc; line-height: 2; margin-bottom: 10px"
      >
        <span class="tableIndex"></span>
        <span class="tableCol left">合计</span>
        <span class="tableCol">{{ entity.TotalQuantity }}</span>
        <span class="tableCol"></span>
        <span class="tableCol">{{ entity.TotalSum?entity.TotalSum.toFixed(2):'' }}</span>
      </div>
      <div class="cell">
        <span v-if="entity.Type == 0" style="width:150rpx">现收: {{entity.ReceiveSum ? entity.ReceiveSum.toFixed(2):0}}</span>
        <span v-if="entity.Type == 1" style="width:150rpx">挂账: {{entity.PendingSum ? entity.PendingSum.toFixed(2):0}}</span>
      </div>
      <!-- <div style="text-align: center; padding-top: 30px">
        <span style="display: block">扫码查看电子小票</span>
        <vue-qr
          class="box"
          :text="codeValue"
          :logoScale="0.2"
          :size="200"
        ></vue-qr>
      </div> -->
    </div>
    <el-button v-if="href" class="fixBtn" type="primary">
      <a :href="href" style="color: #fff">去签收</a>
    </el-button>
  </el-drawer>
</template>
  
<script>
import wx from "weixin-js-sdk";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      data: [],
      codeValue: "",
      entity: {},
      href: "",
      drawer: false,
      IsOverdue: "",
      // 逾期天数
      OverdueDay: "",
    };
  },
  // mounted() {
  //     this.codeValue = location.href;
  //     this.getTheData(this.$route.query.Id);
  //     if (this.$route.query.billId) this.getFormData(this.$route.query.billId);
  //     this.getShareInfo();
  // },
  methods: {
    openForm(Id) {
      this.drawer = true;
      this.codeValue = location.href;
      this.getTheData(Id);
      // if (this.$route.query.billId) this.getFormData(this.$route.query.billId);
      this.getShareInfo();
    },
    // getFormData(id) {
    //     this.$http.post("/BO/BillList/GetBillDetail", { id }).then((res) => {
    //         if (res.Success) {
    //             this.$http
    //                 .post("/BO/BillList/GetBillLink", {
    //                     id: res.Data.QrCode,
    //                 })
    //                 .then((result) => {
    //                     if (result.Success) {
    //                         this.href = result.Data;
    //                     }
    //                 });
    //         }
    //     });
    // },
    // 获取商品详情
    getTheData(Id) {
      console.log("获取传递过来的id", Id);
      this.$http.post("/BO/BIlling/GetBllingDetail?Id=" + Id).then((res) => {
        if (res.Success) {
          console.log("获取下票页面结果", res);
          this.data = res.Data.detalist;
          this.entity = res.Data.maindata;
          this.getSelectOverdue(res.Data.maindata.Id);
        } else this.$message.error(res.Msg);
      });
    },
    // 获取逾期天数逾期状态数据
    getSelectOverdue(Id) {
      this.$http.post("/BO/BIlling/SelectOverdue?Id=" + Id).then((res) => {
        if (res.Success) {
          this.IsOverdue = res.Data.IsOverdue;
          this.OverdueDay = res.Data.OverdueDay;
          console.log(
            "是否成功了是否成功了是否成功了",
            res,
            this.IsOverdue,
            this.OverdueDay
          );
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://hzq.yoojet.com/"
      );
      let arr;
      if (ticketData.Data.length !== 0) {
        arr = ticketData.Data.split(",");
      }

      const config = {
        appId: "wxde354ac424a66adc",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "回执签",
          desc: this.entity.CompanyName + "的电子小票",
          link: location.href,
          imgUrl: "https://hzq.yoojet.com/wx.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
  },
};
</script>
  
<style lang="less" scoped>
.fixBtn {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.Receipt {
  .container {
    margin: 0 auto;
    color: #000;
    min-width: 216px;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;

    h3 {
      text-align: center;
    }

    .cell {
      display: flex;
      line-height: 2;

      span {
        width: 40px;
      }
    }

    .tableRow {
      font-size: 12px;
      display: flex;
      align-items: center;

      span {
        overflow: hidden;
      }

      .tableCol {
        text-align: right;
        width: 75px;
        overflow: hidden;
      }

      .left {
        text-align: left;
        flex: 1;
      }

      .tableIndex {
        width: 25px;
      }

      .col2 {
        width: 150px;
      }
    }
  }
}
</style>